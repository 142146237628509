<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Galeri</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="auto-container">
        <!--Mixit Galery-->

        <div v-if="Resimler.length > 0" class="mixit-gallery filter-gallery">
          <div class="filter-gallery-one row clearfix">
            <!--Gallery Item-->

            <div
              v-for="(resim, index) in Resimler"
              :key="index"
              class="
                gallery-block
                mix
                all
                tour
                industry
                col-lg-4 col-md-6 col-sm-12
              "
            >
              <div
                class="inner-box wow fadeInUp"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="image-box">
                  <figure class="image">
                    <img :src="resim.dosyaurl" alt />
                  </figure>

                  <div class="zoom-btn">
                    <a
                      class="lightbox-image zoom-link"
                      :href="resim.dosyaurl"
                      data-fancybox="gallery"
                    >
                      <span class="icon flaticon-zoom-in"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center">
            <button @click="loadmore()" class="loadclass">Daha fazla</button>
          </div>

          <!--<div class="load-more text-center">

                    <a href="portfolio-grid-2.html" class="theme-btn btn-style-one"><span class="btn-title">Load More</span></a>

          </div>-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "galeri",
  data() {
    return {
      Resimler: [],
      loadkont: false,
    };
  },
  methods: {
    scripts() {
      let customscript2 = document.createElement("script");
      customscript2.setAttribute("src", "/js/mixitup.js");
      document.head.appendChild(customscript2);
      let customscript3 = document.createElement("script");
      customscript3.setAttribute("src", "/js/mixitup-loadmore.js");
      document.head.appendChild(customscript3);
      let customscript = document.createElement("script");
      customscript.setAttribute("src", "/js/custom-script.js");
      document.head.appendChild(customscript);
    },
    async loadmore() {
      const son = this.Resimler[this.Resimler.length - 1];
      await firebase
        .firestore()
        .collection("WebDosyalar")
        .where("dosyailgisi", "==", "galeri")
        .orderBy("dosyaId", "desc")
        .startAfter(son.dosyaId)
        .limit(6)
        .get()
        .then((result) => {
          result.forEach((doc) => {
            const resim = doc.data();
            this.Resimler.push(resim);
          });
          this.scripts();
        })
        .catch((err) => {
          console.log("galerihata" + err);
        });
    },
  },
  async created() {
    await firebase
      .firestore()
      .collection("WebDosyalar")
      .where("dosyailgisi", "==", "galeri")
      .orderBy("dosyaId", "desc")
      .limit(6)
      .get()
      .then((result) => {
        var resmler = [];
        result.forEach((doc) => {
          const resim = doc.data();
          resmler.push(resim);
        });
        this.Resimler = resmler;
        this.scripts();
      })
      .catch((err) => {
        console.log("galerihata" + err);
      });
    this.loadkont = true;
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: #936848;
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Manjari", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
.loadmore2 {
  position: relative;
  text-align: center;
}

.loadmore2 .loadmore2-button,
.loadmore2 .loadmore2-less {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: #936848;
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Manjari", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.loadmore2 .loadmore2-button:hover,
.loadmore2 .loadmore2-less:hover {
  background: #222222;
  color: #ffffff;
}

.loadmore2 .loadmore2-less,
.loadmore2 .loadmore2-button[disabled="disabled"] {
  display: none;
}
</style>