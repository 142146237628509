<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="BirimBilgisi.birimFotoUrl" alt />
                    </figure>
                  </div>

                  <h2>{{BirimBilgisi.birimadi}}</h2>

                  <p style="white-space: pre-line">{{BirimBilgisi.birimaciklamasi}}</p>
                </div>

                <div class="share-post">
                  <strong>Bizi Sosyal Medyadan Takip Edin</strong>

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a href="#">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a href="#">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a href="#">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>

                    <li class="pinterest">
                      <a href="#">
                        <span class="icon fab fa-pinterest-p"></span>
                        <span class="txt">Pinterest</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase/app'
import Sidebar from '@/views/Sidebar'
export default {
    name:'Birimdetay',
    data(){
        return{
            BirimBilgisi:{},
            loadkont:false
        }
    },
    components:{
        Sidebar
    },
    async created(){
        const ID=this.$route.params.birimId;
        console.log('IDD.'+ID);
        await firebase.firestore().collection('WebBirimler').doc(ID).get()
        .then((result) => {
            const data1=result.data();
            console.log('dat'+data1);
            this.BirimBilgisi=result.data();
        }).catch((err) => {
            console.log('birimdetayhata'+err);
        });
        this.loadkont=true;
    }
}
</script>