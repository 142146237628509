
export const kontroller=(state,kontrol)=>{
    switch (kontrol.hangisi) {
        case 'arkaplan':
            state.arkaplankontrol=kontrol.bool;
            state.Arkaplan=kontrol.data;
            break;
        case 'haber':
            state.haberkontrol=kontrol.bool;
            state.Haberler=kontrol.data;
            break;
        case 'baskan':
            state.baskankontrol=kontrol.bool;
            state.Baskan=kontrol.data;
            break;         
    
        default:
            break;
    }
};
export const haberler=(state,haberler)=>{
    state.Haberler=haberler;
}