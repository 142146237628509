import { db } from '@/yardimcilar/firebaseinit';
// import { auth } from '@/yardimcilar/firebaseinit';
// import { storage } from '@/yardimcilar/firebaseinit';
// import { firebase } from '@/yardimcilar/firebaseinit';


export const arkaplancek=async(context)=>{
    if(context.state.arkaplankontrol==false){
        await db.collection('Belediyebilgiler').doc('belediye').get()
        .then((result) => {
            const data =result.data();
            const url=data.anasayfakapak;
            context.commit('arkaplan',url);
            context.state.arkaplankontrol=true;
        }).catch((err) => {
            console.log(err);
        });
    }else{
        alert('Çekildi');
    }
};
export const haberlercek=async(context)=>{
    if(context.state.haberkontrol==false){
        await db.collection('WebHaberler').orderBy("giristarih","desc").limit(4).get().then(function (querySnapshot) {
            var liste = [];
            querySnapshot.forEach(function (doc) {
                // doc.data() is never undefined for query doc snapshots
                const data1 = doc.data();
                liste.push(data1);
            });
            context.commit('haberler',liste);
        })
            .catch(function (error) {
                console.log(context.domainId);
                console.log("Error getting documents: ", error);
            });
    }else{
        alert('Habercekildi');
    }
};
export const baskancek=async(context) => {
    await db.collection('Baskanbilgilerweb').doc('baskan').get()
    .then((result) => {
        const data=result.data();
        context.state.Baskanbilgisi=data;
    }).catch((err) => {
        alert(err);
    });
};

/*<div v-for="haber in Haberler" :key="haber.haberId" class="hi-block">
<div class="inner-box">
  <div class="upper-info">
    <div class="count">02</div>

    <h3>
      <a>
        Sonraki
        <br />haber
      </a>
    </h3>
  </div>

  <div class="image-box">
    <figure class="filtre alev" style="max-height: 430px;">
      <a href="event-details.html">
        <img :src="haber.haberkapakurl" alt />
      </a>
    </figure>

    <div class="image-cap clearfix">
      <h2>
        <a href="event-details.html">
          <span class="num">02</span>Mezarlık çevresini genişleterek taziye evi yapımında sona yaklaşıyoruz
        </a>
      </h2>
    </div>
  </div>
</div>
</div>*/