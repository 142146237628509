<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Haberler</h1>
          </div>
        </div>
      </div>
    </section>
    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="main-image-box">
                    <figure class="image">
                      <img :src="HaberBilgisi.haberkapakurl" alt />
                    </figure>
                  </div>

                  <h2>{{HaberBilgisi.haberadi}}</h2>
                  <h5 style="margin-top: -10px;">{{HaberBilgisi.habertarihi}}</h5>

                  <p style="white-space: pre-line;">{{HaberBilgisi.haberaciklamasi}}</p>

                  <div class="mixit-gallery filter-gallery">
                    <h3 style="color: black;">Haber Fotoğrafları</h3>
                    <div
                      v-if="Resimler.length>0"
                      class="filter-gallery-one row clearfix"
                      id="MixItUp7C2D6E"
                    >
                      <!--Gallery Item-->

                      <div
                        v-for="(resim,index) in Resimler"
                        :key="index"
                        class="gallery-block mix all tour industry col-lg-4 col-md-4 col-sm-12"
                        style="display: inline-block;"
                      >
                        <div
                          class="inner-box wow fadeInUp animated"
                          data-wow-delay="0ms"
                          data-wow-duration="1500ms"
                          style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: fadeInUp;"
                        >
                          <div class="image-box" style="height=100px">
                            <figure class="image">
                              <img :src="resim.dosyaurl" alt />
                            </figure>

                            <div class="zoom-btn">
                              <a
                                class="lightbox-image zoom-link"
                                :href="resim.dosyaurl"
                                data-fancybox="gallery"
                              >
                                <span class="icon flaticon-zoom-in"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--<div class="load-more text-center">
                    
                                        <a href="portfolio-grid-2.html" class="theme-btn btn-style-one"><span class="btn-title">Load More</span></a>
                    
                    </div>-->
                  </div>
                </div>

                <div class="share-post">
                  <strong>Bizi Sosyal Medyadan Takip Edin</strong>

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a href="#">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a href="#">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a href="#">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>

                    <li class="pinterest">
                      <a href="#">
                        <span class="icon fab fa-pinterest-p"></span>
                        <span class="txt">Pinterest</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import Sidebar from "@/views/Sidebar.vue";
export default {
  name: "Haberdetay",
  data() {
    return {
      HaberBilgisi: {},
      Resimler: {},
      loadkont:false
    };
  },
  components: {
    Sidebar
  },
  async created() {
    await this.haberdetaycek();
    await this.haberfotolar();
    this.loadkont=true;
  },
  mounted() {
    this.scripts();
  },
  methods: {
    scripts() {
      let customscript2 = document.createElement("script");
      customscript2.setAttribute("src", "/js/mixitup.js");
      document.head.appendChild(customscript2);
      let customscript3 = document.createElement("script");
      customscript3.setAttribute("src", "/js/mixitup-loadmore.js");
      document.head.appendChild(customscript3);
      let customscript = document.createElement("script");
      customscript.setAttribute("src", "/js/custom-script.js");
      document.head.appendChild(customscript);
    },
    async haberdetaycek() {
      const ID = this.$route.params.haberId;
      await firebase
        .firestore()
        .collection("WebHaberler")
        .doc(ID)
        .get()
        .then(result => {
          const data = result.data();
          this.HaberBilgisi = data;
        })
        .catch(err => {
          console.log("haberdetayhata" + err);
        });
    },
    async haberfotolar() {
      const ID = this.$route.params.haberId;
      await firebase
        .firestore()
        .collection("WebDosyalar")
        .where("dosyailgisi", "==", "haber")
        .where("ilgiId", "==", ID)
        .get()
        .then(result => {
          var resimlerr = [];
          result.forEach(doc => {
            const resim = doc.data();
            resimlerr.push(resim);
          });
          this.Resimler = resimlerr;
        })
        .catch(err => {
          console.log("dosyahata" + err);
        });
    }
  }
};
</script>