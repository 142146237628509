<template>
  <div>
    <footer class="main-footer">
      <!--Widgets Section-->

      <div class="widgets-section">
        <div class="auto-container">
          <div class="row clearfix">
            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h4>Başkan</h4>
                </div>

                <div class="widget-content">
                  <ul class="links">
                    <li>
                      <router-link to="/ozgecmis">Başkan Özgeçmiş</router-link>
                    </li>

                    <li>
                      <router-link to="/mesaj">Başkan'dan Mesaj</router-link>
                    </li>

                    <li>
                      <router-link to="/baskangaleri">Başkan Albüm</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <div class="widget-title">
                  <h4>Kurumsal</h4>
                </div>

                <div class="widget-content">
                  <ul class="links">
                    <li>
                      <router-link to="/yardimci"
                        >Başkan Yardımcımız</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/birimler">Birimler</router-link>
                    </li>

                    <li>
                      <router-link to="/meclisuyeleri"
                        >Belediye Meclis Üyeleri</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/muhtarlar">Muhtarlarımız</router-link>
                    </li>

                    <li>
                      <router-link to="/ilgenel"
                        >İl Genel Meclis Üyeleri</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget num-widget">
                <div class="widget-title">
                  <h4>Kaman</h4>
                </div>

                <div class="widget-content">
                  <ul class="num-links">
                    <li>
                      <router-link to="/tarihce">Tarihçe</router-link>
                    </li>

                    <li>
                      <router-link to="/kentrehberi">Kent Rehberi</router-link>
                    </li>

                    <li>
                      <router-link to="/vefat">Vefat Edenler</router-link>
                    </li>

                    <li>
                      <router-link to="/baskanlarimiz"
                        >Başkanlarımız</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/galeri">Galeri</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!--Column-->

            <div class="column col-lg-3 col-md-6 col-sm-12">
              <div class="footer-widget about-widget">
                <div class="logo">
                  <img
                    src="images/icons/logoson.png"
                    style="height: 40px"
                    alt
                  />
                </div>

                <div class="address">
                  <h5>Kaman Belediyesi</h5>

                  <div class="text">
                    Cumhuriyet Meydanı ATATÜRK Caddesi
                    <br />Kaman / Kırşehir
                  </div>
                  <div class="text pt-2">
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/kaman1.appspot.com/o/Ki%C5%9Fisel%20Veri%20Saklama%20ve%20%C4%B0mha%20Politikas%C4%B1.pdf?alt=media&token=2ebe4ba0-7a04-4e1a-b8fa-0ab93c59aeee"
                      target="_blank"
                      >Kişisel Veri Saklama Ve İmha Politikası</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer Bottom -->

      <div class="footer-bottom">
        <div class="auto-container">
          <div class="inner">
            <div class="copyright">
              Copyrights
              <a href="#">&copy; 2021 Kaman Belediyesi</a> Tüm Hakları Saklıdır.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>