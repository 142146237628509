<template>
  <div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Meclis Kararları</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="events-list">
                <!--Event Block-->
                <div
                 v-for="(karar, index) in kararlar"
                  :key="index"
                  class="event-block">
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">{{
                            karar.karartarihi.split(".")[0]
                          }}</span>
                          <span class="month">{{
                            karar.karartarihi.split(".")[1]
                          }}</span>
                          <span class="year" style="font-size:18px;">{{
                            karar.karartarihi.split(".")[2]
                          }}</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a :href="karar.kararurl" target="blank"
                            >Meclis Kararı</a
                          >
                        </div>

                        <h3>
                          <a
                            :href="karar.kararurl"
                            target="blank"
                            >{{ karar.kararadi.slice(0,30) }}</a
                          >
                        </h3>

                        <div class="location">
                          Kaman Belediyesi
                        </div>

                        <div class="read-more">
                          <a :href="karar.kararurl" target="blank"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div style="text-align: center;">
                <button  class="loadclass">Daha fazla</button>
              </div> -->
            </div>
          </div>
          <!--Sidebar Side-->
          <Sidebar />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar";
import firebase from "firebase/app";

export default {
  name: "Mecliskararlari",
  data() {
    return {
      loadkont: false,
      kararlar: []
    };
  },
  components: {
    Sidebar
  },

  async created() {
    await firebase
      .firestore()
      .collection("WebMeclisKararlar")
      .orderBy("kararId", "desc")
      .get()
      .then(result => {
        result.forEach(doc => {
          const karar = doc.data();
          this.kararlar.push(karar);
        });
      })
      .catch(err => {
        console.log("projehata" + err);
      });
      this.loadkont=true;
  },

  methods: {
    // async loadmore() {
    //   const son = this.projeler[this.projeler.length-1];
    //   const ID=son.projeId;
    //   console.log(ID);
    //   await firebase
    //     .firestore()
    //     .collection("WebMeclisKararlar")
    //     .orderBy("kararId","desc")
    //     .get()
    //     .then(result => {
    //       result.forEach(doc => {
    //         const proje = doc.data();
    //         console.log('2'+proje.projeId);
    //         this.projeler.push(proje);
    //       });
    //     })
    //     .catch(err => {
    //       console.log("mecliskararihata" + err);
    //     });
    // },
  },
};
</script>
<style>
.loadclass {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 20px;
  min-width: 180px;
  text-transform: capitalize;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  padding: 18px 30px 12px;
  background: #936848;
  border-radius: 0px;
  border-radius: 0px;
  overflow: hidden;
  font-family: "Manjari", sans-serif;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  align-items: center;
}
.loadclass:hover {
  background: #222222;
  color: #ffffff;
}
</style>