<template>
  <div>
    <div v-if="!loadkontrol" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Başkanlarımız</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="auto-container">
        <div class="row">
          <div v-for="(baskan,index) in Baskanlar" :key="index" class="col-sm-6 col-md-3">
            <div class="team-block">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <img :src="baskan.foto" alt />
                  </figure>

                  <div class="hover-box">
                    <div class="hover-inner">
                      <div class="hover-upper">
                     

                        <h6 @click="detayagit(baskan.ID)"  >{{baskan.adi}}</h6>
                      </div>

                      <div class="hover-lower">
                        <ul class="info">
                          <li>
                            <a @click="detayagit(baskan.ID)" >{{baskan.donemi}}</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="lower-box">
                  <h4>
                    <a @click="detayagit(baskan.ID)" >{{baskan.adi}}</a>
                  </h4>

                  <div class="designation">Başkan</div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from 'firebase/app'
export default {
    name:'Baskanlar',
    data(){
        return{
            Baskanlar:[],
            loadkontrol:false,
        }
    },
    methods:{
        detayagit(ID){
            this.$router.push('/baskanlarimizdetay/'+ID);
        }
    },
    async created(){
        await firebase.firestore().collection('Baskanlarimiz').get()
        .then((result) => {
            result.forEach((doc)=>{
                const baskan=doc.data();
                this.Baskanlar.push(baskan);
            });
        }).catch((err) => {
            console.log('baskanlarhata'+err);
        });
        this.loadkontrol=true;
    }
}
</script>