<template>
  <div id="app">
    <div class="page-wrapper">
      <Header />
      <router-view />
      <Footer />
      <div class="scroll-to-top scroll-to-target" data-target="html">
        <span class="icon flaticon-up-arrow-angle"></span>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "js/custom-script.js");
    document.head.appendChild(customscript);
  }
};
</script>
<style>
</style>
