<template>
<div class="sidebar-side col-lg-4 col-md-12 col-sm-12">
          <aside class="sidebar">
            <div class="bg-layer">
              <div
                class="image-layer"
                style="background-image:url(images/background/sidebar-bg-image.jpg);"
              ></div>
            </div>

            <div class="sidebar-widget cat-widget">
              <div class="widget-content">
                <div class="sidebar-title">
                  <h4>Hızlı Menü</h4>
                </div>

                <ul class="cat-links clearfix">
                  <li>
                    <router-link to="/ozgecmis">Başkanımız</router-link>
                  </li>

                  <li>
                    <router-link to="/haberler">Haberler</router-link>
                  </li>

                  <li>
                    <router-link to="/duyuruilanlar">Duyurular</router-link>
                  </li>

                  <li>
                  <router-link to="/galeri">Galeri</router-link>
                  </li>

                  <li>
                    <router-link to="/vefat">Vefat Edenler</router-link>
                  </li>

                  <li>
                    <router-link to="/belgeler">Belge Örnekleri</router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="sidebar-widget recent-posts">
              <div class="widget-inner">
                <div class="sidebar-title">
                  <h4>Son Haberler</h4>
                </div>

                <div class="recent-posts-box">
                  <div v-for="(haber,index) in Haberler" :key="index" class="post">
                    <div class="inner">
                      <figure class="post-thumb">
                        <img :src="haber.haberkapakurl" alt />
                        <a @click="detayagit(haber.haberId)" class="overlink">
                          <span class="icon flaticon-zoom-in"></span>
                        </a>
                      </figure>

                      <div class="post-date">{{haber.habertarihi}}</div>

                      <h5 class="title">
                        <a @click="detayagit(haber.haberId)">{{haber.haberadi}}</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
</template>
<script>
export default {
  name: "Sidebar",
  data(){
      return{
          Haberler:[],
      }
  },
  created(){
     this.Haberler=this.$store.getters.getHaberler;
  },
  methods:{
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
  }
};
</script>