<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Belge Örnekleri</h1>
          </div>
        </div>
      </div>
    </section>

    <div class="sidebar-page-container">
      <div class="auto-container">
        <div class="row clearfix">
          <!--Content Side-->

          <div class="content-side col-lg-8 col-md-12 col-sm-12">
            <div class="content-inner">
              <div class="single-post">
                <div class="post-details">
                  <div class="service-details">
                    <div v-if="Belgeler.length>0" class="download-links">
                      <h3>Belge Örnekleri</h3>

                      <ul>
                        <li v-for="(belge,index) in Belgeler" :key="index">
                          <a :href="belge.belgeurl" target="_blank" class="clearfix">
                            <span class="icon fa fa-file-pdf"></span>
                            <span class="ttl">{{belge.belgeadi}}</span>
                            <span class="info">Görüntüle</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="share-post">
                  <strong>Bizi Sosyal Medyadan Takip Edin</strong>

                  <ul class="links clearfix">
                    <li class="facebook">
                      <a href="#">
                        <span class="icon fab fa-facebook-f"></span>
                        <span class="txt">Facebook</span>
                      </a>
                    </li>

                    <li class="twitter">
                      <a href="#">
                        <span class="icon fab fa-twitter"></span>
                        <span class="txt">Twiter</span>
                      </a>
                    </li>

                    <li class="linkedin">
                      <a href="#">
                        <span class="icon fab fa-linkedin-in"></span>
                        <span class="txt">Linkedin</span>
                      </a>
                    </li>

                    <li class="pinterest">
                      <a href="#">
                        <span class="icon fab fa-pinterest-p"></span>
                        <span class="txt">Pinterest</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!--Sidebar Side-->

          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from '@/views/Sidebar'
import firebase from 'firebase/app'
export default {
    name:'Belgeler',
    data(){
        return{
            Belgeler:[],
            loadkont:false
        }
    },
    components:{
        Sidebar
    },
    async created(){
        await firebase.firestore().collection('WebOrnekBelgeler').get()
        .then((result) => {
            result.forEach((doc)=>{
                const belge=doc.data();
                this.Belgeler.push(belge);
            })
        }).catch((err) => {
            console.log('belgehata'+err);
        });
        this.loadkont=true;
    }
}
</script>