<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Birimler</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="welcome-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div
            v-for="(birim,index) in Birimler"
            :key="index"
            class="featured-block col-lg-4 col-md-6 col-sm-12"
          >
            <div class="inner-box">
              <div class="content-box">
                <div class="icon-box">
                  <img
                    src="images/icons/logoson.png"
                    alt
                    style="margin-bottom: 10px; padding-right: 15px;"
                  />
                </div>

                <div class="content" style="margin-top: 20px;">
                  <div class="subtitle">Kaman Belediyesi</div>

                  <h4>
                    <a @click="detayagit(birim)">{{birim.birimadi}}</a>
                  </h4>
                </div>
              </div>

              <div class="hover-box">
                <div
                  class="image-layer"
                  style="background-image: url(images/birimarka.jpg);"
                ></div>

                <div class="inner">
                  <h4>
                    <a @click="detayagit(birim)">{{birim.birimadi}}</a>
                  </h4>

                  <div class="text">{{birim.birimaciklamasi.slice(0,110)}}...</div>
                </div>
              </div>

              <div class="more-link">
                <a @click="detayagit(birim)">
                  <span class="flaticon-right-2"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Birimler",
  data() {
    return {
      Birimler: [],
      loadkont: false
    };
  },
  methods: {
    detayagit(birim) {
      const birimIDD = birim.birimId;
      this.$router.push("/birimdetay/" + birimIDD);
    }
  },
  async created() {
    await firebase
      .firestore()
      .collection("WebBirimler")
      .get()
      .then(result => {
        result.forEach(doc => {
          const birim = doc.data();
          this.Birimler.push(birim);
        });
        this.loadkont = true;
      })
      .catch(err => {
        console.log("birim hata" + err);
      });
  }
};
</script>