<template>
  <div>
    <div v-if="!loadkontrol" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Muhtarlarımız</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="portfolio-section loadmore-gallery-one portfolio-mixitup">
      <div class="auto-container">
        <div class="row">
          <div v-for="(uye,index) in Uyeler" :key="index" class="col-sm-6 col-md-3">
            <div class="team-block">
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <img style="height:300px; object-fit: cover;" :src="uye.muhtarfotourl" alt />
                  </figure>

                  <div class="hover-box">
                    <div class="hover-inner">
                      <div class="hover-upper">
                     

                        <h6>{{uye.adi}}</h6>
                      </div>

                      <div class="hover-lower">
                        <ul class="info">
                          <li>
                            <a >{{uye.mahalle}}</a>
                          </li>
                           <li><a>{{uye.telefon}}</a></li>
                           <li><a>{{uye.adres}}</a></li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="lower-box">
                  <h4>
                    <a>{{uye.adi}}</a>
                  </h4>

                  <div class="designation">{{uye.mahalle}}</div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from 'firebase/app'
export default {
    name:'Muhtarlar',
    data(){
        return{
            Uyeler:[],
            loadkontrol:false,
        }
    },
    async created(){
        await firebase.firestore().collection('WebMuhtarlar').get()
        .then((result) => {
            result.forEach((doc)=>{
                const uye=doc.data();
                this.Uyeler.push(uye);
            });
            this.loadkontrol=true;
        }).catch((err) => {
            console.log('muhtarhata'+err);
        });
    }
}
</script>