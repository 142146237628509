<template>
  <div>
    <!--Page Title-->
    <div v-if="yollakont" class="preloader">
      <div class="icon"></div>
    </div>
    <div v-if="!yollakont">
      <section class="page-banner">
        <div class="banner-inner">
          <div class="auto-container">
            <div class="inner-container clearfix">
              <h1>İletişim</h1>
            </div>
          </div>
        </div>
      </section>

      <!--Contact Section-->

      <section class="contact-section">
        <div class="auto-container">
          <div class="row clearfix">
            <div class="left-col col-lg-6 col-md-12 col-sm-12">
              <div class="inner-box">
                <div class="images clearfix">
                  <figure class="image">
                    <img src="images/background/slider1.jpg" alt />
                  </figure>

                  <figure class="image">
                    <img src="images/icons/logoson.png" alt />
                  </figure>
                </div>

                <div class="contact-info-box">
                  <div class="inner">
                    <ul class="info">
                      <li class="clearfix">
                        <h4>Bizi Arayın</h4>

                        <div class="content">
                          <span class="icon flaticon-telephone-2"></span>

                          <span>Ara</span>
                          <br />

                          <a class="txt" href="tel:+903867122510"
                            >0 (386) 712 25 10</a
                          >
                        </div>
                      </li>

                      <li class="clearfix">
                        <h4>Email adresimiz</h4>

                        <div class="content">
                          <span class="icon flaticon-postcard"></span>

                          <span>Mail Gönder</span>
                          <br />

                          <a href="mailto:bilgi@kaman.bel.tr" class="txt"
                            >bilgi@kaman.bel.tr</a
                          >
                        </div>
                      </li>

                      <li class="clearfix">
                        <h4>Bizi Ziyaret Edin</h4>

                        <div class="content">
                          <span class="icon flaticon-map"></span>

                          <span class="txt">
                            Cumhuriyet Meydanı ATATÜRK Caddesi
                            <br />Kaman / Kırşehir
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="right-col col-lg-6 col-md-12 col-sm-12">
              <div class="inner">
                <div class="sec-title with-separator">
                  <h2>Sizin İçin Buradayız</h2>

                  <div class="separator">
                    <span class="cir c-1"></span>
                    <span class="cir c-2"></span>
                    <span class="cir c-3"></span>
                  </div>

                  <div class="lower-text">
                    Bize Ulaşmak İçin Lütfen Formu Doldurunuz!.
                  </div>
                </div>

                <div class="default-form form-box">
                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        v-model="adii"
                        type="text"
                        name="username"
                        placeholder="Adınız"
                        required
                        value
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        v-model="gidecekmail"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        value
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <textarea
                        v-model="mesaj"
                        name="message"
                        placeholder="Mesajınız..."
                        required
                      ></textarea>
                    </div>
                  </div>

                  <button @click="yolla()" class="theme-btn btn-style-one">
                    <span class="btn-title">Gönder</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--Map Section-->

      <section class="map-section container pb-5">
        <!--Map Box-->
        <div class="map-box">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12339.566210742989!2d33.7233775!3d39.3586924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x38d2c0c0d916662e!2sKaman%20Belediyesi!5e0!3m2!1str!2str!4v1639639727360!5m2!1str!2str"
            width="100%"
            height="400"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Iletisim",
  data() {
    return {
      yollakont: false,
      adii: "",
      gidecekmail: "",
      mesaj: "",
    };
  },
  methods: {
    async yolla() {
      if (this.adii == "" || this.gidecekmail == "" || this.mesaj == "") {
        alert("Lütfen bilgileri eksiksiz doldurun");
      } else {
        this.yollakont = true;
        const yeniIDD = Date.now().toString();
        await firebase.firestore().collection("Mailler").doc(yeniIDD).set({
          adi: this.adii,
          gidecekmail: this.gidecekmail,
          mesaj: this.mesaj,
        });
        this.yollakont = false;
        this.adii = "";
        this.gidecekmail = "";
        this.mesaj = "";
        setTimeout(() => {
          alert("Mesajınız tarafımıza ulaşmıştır ilginiz için teşekkürler");
        }, 400);
      }
    },
  },
};
</script>