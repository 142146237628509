<template>
  <div>
    <section class="contact-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="right-col col-lg-12 col-md-12 col-sm-12">
            <div class="inner">
              <div class="sec-title with-separator">
                <h2>Başkana Sor</h2>

                <div class="separator">
                  <span class="cir c-1"></span><span class="cir c-2"></span
                  ><span class="cir c-3"></span>
                </div>

                <div class="lower-text"></div>
              </div>
              <!-- method="post" action="sendemail.php" -->
              <div @submit="sendEmail" class="default-form form-box">
                <form>
                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="text"
                        name="username"
                        placeholder="Adınız "
                        required
                        v-model="senderName"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                        v-model="email"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <input
                        type="text"
                        name="username"
                        placeholder="Konu Başlığı "
                        required
                        v-model="subject"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="field-inner">
                      <textarea
                        name="message"
                        placeholder="Mesajınız..."
                        required
                        v-model="message"
                      ></textarea>
                    </div>
                  </div>

                  <div class="form-group">
                    <button type="submit" class="theme-btn btn-style-one">
                      <span class="btn-title">Gönder</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  name: "BaskanaSor",
  data() {
    return {
      senderName: "",
      email: "",
      subject: "",
      message: "",
    };
  },
  methods: {
    sendEmail(e) {
      e.preventDefault();
      try {
        emailjs.sendForm(
          "service_2t5pyyn",
          "template_502yd4i",
          e.target,
          "user_DeQeUt4wLqCMGlTCUqjUD",
          {
            name: this.senderName,
            email: this.email,
            subject: this.subject,
            message: this.message,
          }
        );
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.senderName = "";
      this.email = "";
      (this.subject = ""), (this.message = "");
    },
  },
};
</script>