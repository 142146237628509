<template>
  <div>
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="page-banner">
      

      <div class="banner-inner">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <h1>Evlenenler</h1>
          </div>
        </div>
      </div>
    </section>

    <section class="history-section">
      <div class="pattern-layer" style="background-image: url(images/background/pattern-2.png);"></div>

      <div class="auto-container">
        <div class="history-container">
          <div class="center-line">
            <span class="dot upper-dot"></span>
            <span class="dot lower-dot"></span>
          </div>

          <div v-if="Evlenenler.length>0" class="history-content">
            <!--Block-->

            <div v-for="(evlenen,index) in Evlenenler" :key="index">
              <div class="history-block">
                <div v-if="index %2 == 0" class="row clearfix">
                  <div class="image-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <div class="year-box">
                        <div class="year-inner">
                          <span>{{evlenen.tarih.split("-")[0]}}</span>
                        </div>
                      </div>

                      <div class="image-box">
                        <figure class="image">
                          <img src="images/resource/featured-image-12.jpg" alt />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="text-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <h6>{{evlenen.tarih.split("-")[1]}}</h6>

                      <h3>
                        <a>{{evlenen.gelinadi}} & {{evlenen.damatadi}}</a>
                      </h3>

                      <div class="text">{{evlenen.metin}}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="Math.abs(index % 2) == 1" class="history-block alternate">
                <div class="row clearfix">
                  <div class="image-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <div class="year-box">
                        <div class="year-inner">
                          <span>{{evlenen.tarih.split("-")[0]}}</span>
                        </div>
                      </div>

                      <div class="image-box">
                        <figure class="image">
                          <img src="images/resource/featured-image-13.jpg" alt />
                        </figure>
                      </div>
                    </div>
                  </div>

                  <div class="text-col col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                      <h6>{{evlenen.tarih.split("-")[1]}}</h6>

                      <h3>
                        <a>{{evlenen.gelinadi}} & {{evlenen.damatadi}}</a>
                      </h3>

                      <div class="text">{{evlenen.metin}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="text-align: center;">
                <button @click="dahafazla()" class="loadclass">Daha fazla</button>
              </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from "firebase/app";
export default {
  name: "Evlenenler",
  data() {
    return {
      Evlenenler: [],
      loadkont:false
    };
  },
  methods: {
    async dahafazla() {
        const son=this.Evlenenler[this.Evlenenler.length-1];
      await firebase
        .firestore()
        .collection("WebEvlenenler")
        .orderBy("Id", "desc")
        .startAfter(son.Id)
        .limit(6)
        .get()
        .then(result => {
          result.forEach(doc => {
            const evlenen = doc.data();
            this.Evlenenler.push(evlenen);
          });
        })
        .catch(err => {
          console.log("Evlenenhata" + err);
        });
    }
  },
  async created() {
    await firebase
      .firestore()
      .collection("WebEvlenenler")
      .orderBy("Id", "desc")
      .limit(6)
      .get()
      .then(result => {
        result.forEach(doc => {
          const evlenen = doc.data();
          this.Evlenenler.push(evlenen);
        });
      })
      .catch(err => {
        console.log("Evlenenhata" + err);
      });
      this.loadkont=true;
  }
};
</script>
<style>
.loadclass{
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-top: 20px;
    min-width: 180px;
    text-transform: capitalize;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 700;
    padding: 18px 30px 12px;
    background: #936848;
    border-radius: 0px;
    border-radius: 0px;
    overflow: hidden;
    font-family: 'Manjari', sans-serif;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    align-items: center;
}
.loadclass:hover{
    background: #222222;
    color: #ffffff;
}
</style>