<template>
  <div>
    <header class="main-header header-style-one">
      <!-- Header Top -->

      <div class="header-top header-top-one">
        <div class="auto-container">
          <div class="inner clearfix">
            <div class="top-left clearfix">
              <div class="welcome-text">
                <span ></span>Kaman Belediyesi Resmi Web Sitesi
              </div>
            </div>

            <div class="top-right clearfix">
              <div class="email">
                <a href="https://ebelediye.kaman.bel.tr/ebelediye/genel/ebelediye.xhtml">
                  <span ></span>E-Belediye
                </a>
              </div>
              <div class="email">
                <router-link to="/iletisim">
                  <span class="icon fa fa-envelope"></span>İstek Formu
                </router-link>
              </div>
              <div class="email">
                <a href="mailto:bilgi@kaman.bel.tr">
                  <span class="icon fa fa-envelope"></span>bilgi@kaman.bel.tr
                </a>
              </div>
              <div class="email">
                <a href="tel:03867122510">
                  <span class="icon fa fa-phone-alt"></span>0(386) 712 2510
                </a>
              </div>

              <div class="hours">
                <div class="hours-btn">
                  Hızlı Menü
                  <span class="arrow flaticon-down-arrow"></span>
                </div>

                <div class="hours-dropdown">
                  <ul>
                    <li>
                      <a href="https://ebelediye.kaman.bel.tr/ebelediye/genel/ebelediye.xhtml" target="blank">E-Belediye</a>
                    </li>

                    <li>
                      <router-link to="/vefat">Vefat Edenler</router-link>
                    </li>

                    <li>
                      <router-link to="/kentrehberi">Kent Rehberi</router-link>
                    </li>

                    <li>
                      <router-link to="/belgeler">Belgeler</router-link>
                    </li>

                    <li>
                      <router-link to="/duyuruilanlar">Duyurular</router-link>
                    </li>

                    <li>
                      <router-link to="/galeri">Galeri</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Header Upper -->

      <div class="header-upper">
        <div class="auto-container">
          <div class="inner-container clearfix">
            <!--Logo-->

            <div class="logo-box clearfix">
              <div class="logo">
                <router-link to="/" title="City Government - City Regulatory HTML Template">
                  <img
                    src="images/icons/logoson.png"
                    alt="City Government - City Regulatory HTML Template"
                    title="City Government - City Regulatory HTML Template"
                  />
                </router-link>
              </div>
            </div>

            <!--Nav-->

            <div class="nav-outer clearfix" style="padding-top: 25px">
              <!--Mobile Navigation Toggler-->

              <div class="mobile-nav-toggler">
                <span class="icon flaticon-menu-1"></span>
              </div>

              <!-- Main Menu -->

              <nav class="main-menu navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                  <ul class="navigation clearfix">
                    <li>
                      <router-link to="/"> Anasayfa</router-link>
                    </li>

                    <li class="dropdown">
                      <router-link to="/ozgecmis"> Başkanımız</router-link>

                      <ul>
                        <li>
                         <router-link to="/ozgecmis"> Başkan Özgeçmiş</router-link>
                        </li>

                        <li>
                          <router-link to="/mesaj"> Başkan Mesaj</router-link>
                        </li>

                        <li>
                          <router-link to="/baskangaleri"> Başkan Galeri</router-link>
                        </li>

                        <li>
                          <router-link to="/baskanasor"> Başkan'a Sor</router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="dropdown">
                      <router-link to="/yardimci"> Kurumsal</router-link>

                      <ul>
                        <li>
                          <router-link to="/yardimci"> Başkan Yardımcımız</router-link>
                        </li>
                        <li>
                          <router-link to="/birimler"> Birimler</router-link>
                        </li>
                        <li>
                          <router-link to="/ilgenel"> İl Genel Meclis Üyeleri</router-link>
                        </li>

                        <li>
                          <router-link to="/meclisuyeleri"> Belediye Meclis Üyeleri</router-link>
                        </li>
                        <li>
                          <router-link to="/muhtarlar"> Muhtarlarımız</router-link>
                        </li>
                        <li>
                          <router-link to="/kararlar"> Meclis Kararları</router-link>
                        </li>
                        <li>
                          <router-link to="/raporlar"> Faaliyet Raporları</router-link>
                        </li>
                      </ul>
                    </li>

                    <li class="dropdown">
                      <router-link to="/haberler"> Güncel </router-link>

                      <ul>
                        <li>
                          <router-link to="/haberler"> Haberler </router-link>
                        </li>
                        <li>
                         <router-link to="/projeler"> Projeler </router-link>
                        </li>
                        <li>
                         <router-link to="/duyuruilanlar"> Duyurular </router-link>
                        </li>
                        
                      </ul>
                    </li>
                    <li class="dropdown">
                      <router-link to="/tarihce">Kaman</router-link>

                      <ul>
                        <li>
                          <router-link to="/tarihce"> Tarihce </router-link>
                        </li>
                        <li>
                          <router-link to="/kentrehberi"> Kent Rehberi </router-link>
                        </li>
                        <li>
                          <router-link to="/vefat"> Vefat Edenler </router-link>
                        </li>

                        <li>
                           <router-link to="/baskanlarimiz"> Başkanlarımız </router-link>
                        </li>
                        <li>
                          <router-link to="/galeri"> Galeri </router-link>
                        </li>
                        <li>
                          <router-link to="/videolar"> Video Galeri </router-link>
                        </li>
                        <li>
                          <router-link to="/sehitlerimiz"> Şehitlerimiz </router-link>
                        </li>
                        <li>
                          <router-link to="/gazilerimiz"> Gazilerimiz </router-link>
                        </li>
                        <li>
                           <router-link to="/belgeler"> Belge Örnekleri </router-link>
                        </li>
                      </ul>
                    </li>

                    <li >
                      <router-link to="/kamancevizi">Kaman Cevizi</router-link>                      
                    </li>
                    <li >
                      <router-link to="/ataturk">Atatürk</router-link>                      
                    </li>
                    <li>
                      <router-link to="/iletisim"> İletişim</router-link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <!--Other Links-->
            <div style="text-align: right;">
              <img src="/images/ataturkvebayrak.png" alt="" style="padding-left: 30px;">
            </div>
          </div>
        </div>
      </div>

      <!--End Header Upper-->

      <!-- Sticky Header  -->

      <div class="sticky-header">
        <div class="auto-container clearfix">
          <!--Logo-->

          <div class="logo pull-left">
            <a href="index.html" title>
              <img src="images/sticky-logo.png" alt title />
            </a>
          </div>

          <!--Right Col-->

          <div class="pull-right">
            <!-- Main Menu -->

            <nav class="main-menu clearfix">
              <!--Keep This Empty / Menu will come through Javascript-->
            </nav>
            <!-- Main Menu End-->
          </div>
        </div>
      </div>
      <!-- End Sticky Menu -->

      <!-- Mobile Menu  -->

      <div class="mobile-menu">
        <div class="menu-backdrop"></div>

        <div class="close-btn">
          <span class="icon flaticon-targeting-cross"></span>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <a href="index.html">
              <img src="images/nav-logo.png" alt title />
            </a>
          </div>

          <div class="menu-outer">
            <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
          </div>

          <!--Social Links-->

          <div class="social-links">
            <ul class="clearfix">
              <li>
                <a href="#">
                  <span class="fab fa-twitter"></span>
                </a>
              </li>

              <li>
                <a href="#">
                  <span class="fab fa-facebook-square"></span>
                </a>
              </li>

              <li>
                <a href="#">
                  <span class="fab fa-pinterest-p"></span>
                </a>
              </li>

              <li>
                <a href="#">
                  <span class="fab fa-instagram"></span>
                </a>
              </li>

              <li>
                <a href="#">
                  <span class="fab fa-youtube"></span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- End Mobile Menu -->
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
};
</script>