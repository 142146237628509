<template>
  <div id="home">
    <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div>
    <section class="banner-section banner-one">
      <div class="banner-carousel owl-theme owl-carousel">
        <!-- Slide Item -->

        <div class="slide-item">
          <div id="testslide" class="image-layer">
            <img :src="urll" alt="" />
          </div>

          <div class="auto-container">
            <div class="content-box">
              <div class="content clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="welcome-section-two">
      <div class="upper-row">
        <div class="auto-container">
          <div class="upper-container">
            <div class="row clearfix">
              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-chat-1"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">Bir Fikrim Var</div>

                      <h4>
                        <router-link to="/iletisim"
                          >Bir fikrin mi var ? Bizimle paylaş...</router-link
                        >
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <router-link to="/iletisim">Bir Fikrim Var</router-link>
                      </h4>

                      <div class="text">
                        Fikrini bizimle paylaşabilir kentimizi beraber
                        güzelleştirmemize katkı sağlayabilirsin.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <router-link to="/iletisim">
                      <span class="flaticon-right-2"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-user"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">Başkana Sor</div>

                      <h4>
                        <router-link to="/baskanasor"
                          >Başkana mesajını ulaştır</router-link
                        >
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <router-link to="/baskanasor">Başkan'a Sor</router-link>
                      </h4>

                      <div class="text">
                        Sorunlarınızı aracısız başkana iletin.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <router-link to="/baskanasor">
                      <span class="flaticon-right-2"></span>
                    </router-link>
                  </div>
                </div>
              </div>

              <!--Feature Block-->

              <div class="featured-block-three col-lg-4 col-md-6 col-sm-12">
                <div class="inner-box">
                  <div class="content-box">
                    <div class="icon-box">
                      <span class="flaticon-headphones"></span>
                    </div>

                    <div class="content">
                      <div class="subtitle">İletişim</div>

                      <h4>
                        <router-link to="/iletisim"
                          >Sorununu bizimle paylaş.</router-link
                        >
                      </h4>
                    </div>
                  </div>

                  <div class="hover-box">
                    <div class="inner">
                      <h4>
                        <router-link to="/iletisim">İletişim</router-link>
                      </h4>
                      <div class="text">
                        Bizimle ileitşime geçin sizin için buradayız.
                      </div>
                    </div>
                  </div>

                  <div class="more-link">
                    <router-link to="/iletisim">
                      <span class="flaticon-right-2"></span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="highlights-section">
        <div class="auto-container">
          <div class="sec-title with-separator centered">
            <h2>Güncel Haberler</h2>

            <div class="separator">
              <span class="cir c-1"></span>
              <span class="cir c-2"></span>
              <span class="cir c-3"></span>
            </div>
          </div>

          <div class="carousel-outer">
            <div v-if="kont == true" class="hi-carousel owl-theme owl-carousel">
              <!--Hi Block-->

              <div
                v-for="(haber, index) in Haberler"
                :key="index"
                class="hi-block"
              >
                <div class="inner-box">
                  <div class="upper-info">
                    <div class="count">{{ index + 1 }}</div>

                    <h3>
                      <a>
                        Sonraki
                        <br />haber
                      </a>
                    </h3>
                  </div>

                  <div class="image-box">
                    <figure class="filtre alev" style="max-height: 430px">
                      <a href="event-details.html">
                        <img :src="haber.haberkapakurl" alt />
                      </a>
                    </figure>

                    <div class="image-cap clearfix">
                      <h2>
                        <a @click="detayagit(haber.haberId)">
                          <span class="num">0{{ index + 1 }}</span>
                          {{ haber.haberadi }}
                        </a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <section class="departments-section">
      <div class="upper-row">
        <div class="bg-layer"></div>

        <div class="auto-container">
          <div class="row clearfix">
            <!--Image Column-->

            <div class="image-column col-xl-3 col-lg-12 col-md-12">
              <div class="inner">
                <figure class="image">
                  <img
                    src="images/background/slider1.jpg"
                    alt
                    style="height: 470px"
                  />
                </figure>
              </div>
            </div>

            <div class="right-column col-xl-9 col-lg-12 col-md-12">
              <div class="inner">
                <div class="sec-title with-separator">
                  <h2>İnteraktif Hizmetlerimiz</h2>

                  <div class="separator">
                    <span class="cir c-1"></span>
                    <span class="cir c-2"></span>
                    <span class="cir c-3"></span>
                  </div>
                </div>

                <div class="departments-carousel owl-theme owl-carousel">
                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>01</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-clipboard-with-a-list"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/duyuruilanlar"
                            >Duyuru & İlanlar</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/duyuruilanlar">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>02</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-user"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <a
                            href="https://ebelediye.kaman.bel.tr/ebelediye/genel/ebelediye.xhtml"
                            >E-Belediye</a
                          >
                        </h4>

                        <div class="read-more">
                          <a
                            href="https://ebelediye.kaman.bel.tr/ebelediye/genel/ebelediye.xhtml"
                          >
                            <span>Görüntüle</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>03</span>
                      </div>

                      <div class="icon-box">
                        <img
                          src="images/icons/nobetciecz.png"
                          style="max-width: 50px; max-heigth: 50px"
                          alt=""
                        />
                      </div>

                      <div class="content">
                        <h4>
                          <a
                            href="https://www.eczaneler.gen.tr/nobetci-kirsehir-kaman"
                            >Nöbetçi Eczaneler</a
                          >
                        </h4>

                        <div class="read-more">
                          <a
                            href="https://www.eczaneler.gen.tr/nobetci-kirsehir-kaman"
                          >
                            <span>Görüntüle</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>04</span>
                      </div>

                      <div class="icon-box">
                        <img
                          src="images/icons/vefatedenler2.png"
                          style="max-width: 50px; max-heigth: 50px"
                          alt=""
                        />
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/vefat">Vefat Edenler</router-link>
                        </h4>

                        <div class="read-more">
                          <router-link to="/vefat">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>05</span>
                      </div>

                      <div class="icon-box">
                        <span class="flaticon-picture"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/galeri"
                            >Kaman Fotoğraflar</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/galeri">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Feature Block-->

                  <div class="featured-block-four">
                    <div class="inner-box">
                      <div class="count-box">
                        <span>06</span>
                      </div>

                      <div class="icon-box">
                        <span class="fa fa-folder-open"></span>
                      </div>

                      <div class="content">
                        <h4>
                          <router-link to="/belgeler"
                            >Belge Örnekleri</router-link
                          >
                        </h4>

                        <div class="read-more">
                          <router-link to="/belgeler">
                            <span>Görüntüle</span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="bottom-text">
                  Mobil uygulamamızı indirerek bir çok hizmete ulaşabilirsiniz
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kamanbelediyesi&hl=tr&gl=US"
                    target="blank"
                    >Google Play Store</a
                  >
                  <a
                    href="https://apps.apple.com/sn/app/kaman-belediyesi/id1541529028"
                    target="blank"
                    style="padding-left: 10px"
                    >App Store</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-section">
      <div class="image-layer"></div>
      <div class="auto-container">
        <div class="content-box">
          <div class="row">
            <img :src="Baskanbilgisi.baskanfotourl" class="col-md-4" alt="" />
            <div class="col-md-2"></div>
            <div class="content col-md-6">
              <div class="sec-title light">
                <h2>
                  {{ this.Baskanbilgisi.baskanadi }}
                  <br />
                  {{ this.Baskanbilgisi.baskanslogan }}
                </h2>
              </div>

              <div class="upper-text clearfix">
                {{ Baskanbilgisi.baskanozgecmis }}
              </div>

              <div class="signature">
                <br />
              </div>

              <div class="info">
                <div class="name">{{ this.Baskanbilgisi.baskanadi }}</div>

                <div class="designation">{{ Baskanbilgisi.baskanslogan }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="events-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="left-column col-xl-8 col-lg-12 col-md-12 col-sm-12">
            <div class="col-inner">
              <div class="sec-title with-separator">
                <h2>Duyurular & İlanlar</h2>

                <div class="separator">
                  <span class="cir c-1"></span>
                  <span class="cir c-2"></span>
                  <span class="cir c-3"></span>
                </div>
              </div>

              <div v-if="Ilanlar.length > 0" class="carousel-box">
                <!--Event Block-->

                <div
                  v-for="(ilan, indx) in Ilanlar"
                  :key="indx"
                  class="event-block"
                >
                  <div class="inner-box">
                    <div class="content-box">
                      <div class="date-box">
                        <div class="date">
                          <span class="day">{{
                            ilan.duyuruilantarihi.split(".")[0]
                          }}</span>
                          <span class="month">{{
                            ay(ilan.duyuruilantarihi.split(".")[1])
                          }}</span>
                        </div>
                      </div>

                      <div class="content">
                        <div class="cat-info">
                          <a @click="duyurudetayagit(ilan.duyuruilanId)"
                            >Duyuru</a
                          >
                        </div>

                        <h3>
                          <a @click="duyurudetayagit(ilan.duyuruilanId)">{{
                            ilan.duyuruilanadi
                          }}</a>
                        </h3>

                        <div class="location">
                          {{ ilan.duyuruilanaciklamasi }}
                        </div>

                        <div class="read-more">
                          <a @click="duyurudetayagit(ilan.duyuruilanId)"
                            >Görüntüle</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="margin-top: 20px; text-align: center">
                  <router-link to="/duyuruilanlar"
                    >Tüm Duyuruları Gör</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="right-column col-xl-4 col-lg-12 col-md-12 col-sm-12">
            <div class="service-details">
              <div class="download-links">
                <h3>Meclis Kararları</h3>
                <ul>
                  <li>
                    <a class="clearfix">
                      <span class="icon fa fa-file-pdf"></span>
                      <span class="ttl">Meclis Kararı Bulunmamaktadır.</span>
                      <span class="info">Kaman Belediyesi</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Services Section-->

    <section class="highlights-section-two">
      <div
        class="image-layer"
        style="background-image: url(images/resource/kentrehberiarkaplan.jpg)"
      ></div>

      <div class="auto-container">
        <div
          v-if="Yerler.length > 0"
          class="featured-carousel owl-theme owl-carousel"
        >
          <!--Featured Block -->

          <div
            v-for="(yer, indx) in Yerler"
            :key="indx"
            class="featured-block-five"
          >
            <div class="inner-box">
              <div class="count-box">
                <span>0{{ indx + 1 }}</span>
              </div>
              <div class="content">
                <h3>
                  <a @click="yerdetayagit(yer.yerId)">{{ yer.yeradi }}</a>
                </h3>

                <div class="text">{{ yer.yeradi }}</div>

                <div class="read-more">
                  <a @click="yerdetayagit(yer.yerId)">
                    <span class="flaticon-right-2"></span>
                  </a>
                </div>
              </div>

              <div class="bottom-text">{{ yer.yeradi }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="Projeler.length > 0" class="news-section">
      <div class="auto-container">
        <div class="sec-title with-separator">
          <h2>Projelerimiz</h2>

          <div class="separator">
            <span class="cir c-1"></span><span class="cir c-2"></span
            ><span class="cir c-3"></span>
          </div>
        </div>
        <div class="carousel-box">
          <div class="news-carousel owl-theme owl-carousel">
            <!--News Block-->
            <div
              v-for="(proje, index) in Projeler"
              :key="index"
              class="news-block"
            >
              <div class="inner-box">
                <div class="image-box">
                  <figure class="image">
                    <img :src="proje.projekapakurl" alt="" />
                  </figure>
                </div>
                <div class="lower-box">
                  <div class="upper-info">
                    <h4>
                      <a @click="projedetayagit(proje.projeId)">{{
                        proje.projeadi
                      }}</a>
                    </h4>

                    <div class="cat-info">
                      <a href="#"
                        ><span class="fa fa-folder"></span>Tamamlanan</a
                      >
                    </div>
                  </div>

                  <div class="meta-info clearfix">
                    <div class="author-info clearfix">
                      <div class="author-icon">
                        <span class="flaticon-user-3"></span>
                      </div>

                      <div class="author-title">Kaman Belediyesi</div>

                      <div class="date"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--Facts Section-->

    <section class="ext-info-section">
      <div
        class="pattern-layer"
        style="background-image: url(images/background/pattern-2.png)"
      ></div>

      <div class="content-row">
        <div class="auto-container">
          <div class="content">
            <h4>Mobil uygulamamızı indirdiniz mi ?</h4>
            <br />
            <div class="link-box">
              <a
                href="https://play.google.com/store/apps/details?id=com.kamanbelediyesi&hl=tr&gl=US"
                class="theme-btn btn-style-one"
                style="margin-right: 15px"
                target="blank"
              >
                <span class="btn-title">Google Play Store</span>
              </a>
              <a
                href="https://apps.apple.com/sn/app/kaman-belediyesi/id1541529028"
                target="blank"
                class="theme-btn btn-style-one"
              >
                <span class="btn-title">App Store</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Ilanlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      Projeler: [],
      loadkont: false,
      kont: false,
    };
  },
  components: {},
  async created() {
    await this.belediyecek();
    await this.haberlercek();
    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.yerlercek();
    await this.projelercek();
    this.scriptekleslide();
  },
  methods: {
    ay(ay1) {
      switch (ay1) {
        case "01":
          return "Ocak";
        case "02":
          return "Şubat";
        case "03":
          return "Mart";
        case "04":
          return "Nisan";
        case "05":
          return "Mayıs";
        case "06":
          return "Haziran";
        case "07":
          return "Temmuz";
        case "08":
          return "Ağustos";
        case "09":
          return "Eylül";
        case "10":
          return "Ekim";
        case "11":
          return "Kasım";
        case "12":
          return "Aralık";
        default:
          break;
      }
    },
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    async haberlercek() {
      const hkontrol = this.$store.getters.gethaberkontrol;
      if (hkontrol == false) {
        firebase
          .firestore()
          .collection("WebHaberler")
          .orderBy("giristarih", "desc")
          .limit(4)
          .get()
          .then(result => {
            var haberlist = [];
            result.forEach(doc => {
              const haber = doc.data();
              haberlist.push(haber);
            });
            this.Haberler = haberlist;
            this.$store.commit("haberler", haberlist);
            this.kont = true;
            // const kontrol = { "hangisi": "haber", "bool": true,"data":haberlist};
            // this.$store.commit("kontroller", kontrol);
            // this.scriptekleslide();
          })
          .catch(err => {
            console.log("haberhatasi" + err);
          });
      } else {
        this.Haberler = this.$store.getters.getHaberler;
        // this.scriptekleslide();
      }
    },
    async belediyecek() {
      await firebase
        .firestore()
        .collection("Belediyebilgiler")
        .doc("belediye")
        .get()
        .then(result => {
          const dat1 = result.data();
          this.urll = dat1.anasayfakapak;
        })
        .catch(err => {
          console.log("belanasayfahata" + err);
        });
    },
    scriptekleslide() {
      // SCRİPT
      let customscript = document.createElement("script");
      customscript.setAttribute("src", "js/custom-script.js");
      document.head.appendChild(customscript);
    },
    async baskancek() {
      const bkontrol = this.$store.getters.getbaskankontrol;
      this.Baskanbilgisi = this.$store.getters.getBaskan;
      if (bkontrol == false) {
        await firebase
          .firestore()
          .collection("Baskanbilgilerweb")
          .doc("baskan")
          .get()
          .then(result => {
            const data = result.data();
            this.Baskanbilgisi = data;
            const kontrol = { hangisi: "baskan", bool: true, data: data };
            this.$store.commit("kontroller", kontrol);
          })
          .catch(err => {
            console.log("baskanhata" + err);
          });
      } else {
        console.log("Baskancekildi");
      }
    },
    async duyuruilancek() {
      await firebase
        .firestore()
        .collection("WebDuyuruIlan")
        .orderBy("duyuruilantarihi", "desc")
        .get()
        .then(result => {
          var ilanlist = [];
          result.forEach(doc => {
            const ilan = doc.data();
            //console.dir(ilan);
            ilanlist.push(ilan);
          });
          this.Ilanlar = ilanlist;
        })
        .catch(err => {
          console.log("duyuruerr" + err);
        });
    },
    async yerlercek() {
      await firebase
        .firestore()
        .collection("WebYerler")
        .orderBy("giristarih")
        .limit(6)
        .get()
        .then(result => {
          var yerlist = [];
          result.forEach(doc => {
            const yer = doc.data();
            yerlist.push(yer);
          });
          this.Yerler = yerlist;
        })
        .catch(err => {
          console.log("yerhata" + err);
        });
    },
    async projelercek() {
      await firebase
        .firestore()
        .collection("WebProjeler")
        .orderBy("projetarih", "desc")
        .limit(6)
        .get()
        .then(result => {
          var projeler = [];
          result.forEach(doc => {
            const proje = doc.data();
            projeler.push(proje);
          });
          this.Projeler = projeler;
        })
        .catch(err => {
          console.log("projehata" + err);
        });
    },
  },
  mounted() {},
};
</script>
<style></style>
