import firebase from 'firebase/app'
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

var firebaseConfig  = {
    apiKey: "AIzaSyDdoN8vv94Zxp7ZeXpWD9m26kkwnak6Pgw",
    authDomain: "kaman1.firebaseapp.com",
    databaseURL: "https://kaman1.firebaseio.com",
    projectId: "kaman1",
    storageBucket: "kaman1.appspot.com",
    messagingSenderId: "255555330526",
    appId: "1:255555330526:web:e5d7bedd10adfe6472fd4f",
    measurementId: "G-WVT5DMKJH4"
}
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export{
    firebase,
    auth,
    db,
    storage
}
